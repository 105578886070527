import Home from "./home/Home";
import Program from "./program/Program";
import Possibilities from "./possibilities/Possibilities";
import Triangle from "./triangle/Triangle";
import ArtAndPublic from "./artandpublic/ArtAndPublic";
import ArtistInResidency from "./artistinresidency/ArtistInResidency";
import About from "./about/About";
import Bookstore from "./bookstore/Bookstore";
import ArtistProfile from "./artistprofile/ArtistProfile.js";
import EmployeeProfile from "./employeeprofile/EmployeeProfile";
import AboutHKS from "./abouthks/AboutHKS.js";
import ProgramPage from "./programpage/ProgramPage";
import PossibilitiesPage from "./possibilitiespage/PossibilitiesPage";
import TrianglePage from "./trianglepage/TrianglePage";
import Search from "./search/Search";

export {
  Home,
  Program,
  Possibilities,
  Triangle,
  ArtAndPublic,
  ArtistInResidency,
  About,
  Bookstore,
  ArtistProfile,
  EmployeeProfile,
  AboutHKS,
  ProgramPage,
  PossibilitiesPage,
  TrianglePage,
  Search,
};
