// imports  here
import MainNavBar from "./mainnavbar/MainNavBar";

import HomeSection3 from "./home/home-section-3/HomeSection3";
import HomeSection2 from "./home/home-section-2/HomeSection2";
import HomeSection1 from "./home/home-section-1/HomeSection1";
import HomeSection4 from "./home/home-section-4/HomeSection4";
import HomeSection5 from "./home/home-section-5/HomeSection5";

import ProgramGrid from "./programs/program-grid/ProgramGrid";
import PreviousProgramsSearch from "./programs/previous-programs/PreviousProgramsSearch";
import UpcomingProgramList from "./programs/upcomingprogramlist/UpcomingProgramList";

import PossibilitiesSection1 from "./possibilities/possibilities-section-1/PossibilitiesSection1";
import PossibilitiesSearch from "./possibilities/possibilitiessearch/PossibilitiesSearch";
import PossibilitiesMandate from "./possibilities/possibilities-mandate/PossibilitiesMandate";

import ArtAndPublicSection1 from "./artandpublic/artandpublic-section1/ArtAndPublicSection1";
import ArtAndPublicSection2 from "./artandpublic/artandpublic-section2/ArtAndPublicSection2";
import ArtAndPublicSection3 from "./artandpublic/artandpublic-section3/ArtAndPublicSection3";
import ArtAndPublicSection4 from "./artandpublic/artandpublic-section4/ArtAndPublicSection4";
import ArtAndPublicSection5 from "./artandpublic/artandpublic-section5/ArtAndPublicSection5";
import ArtAndPublicSection6 from "./artandpublic/artandpublic-section6/ArtAndPublicSection6";

import ArtistInResidencySection1 from "./artistinresidency/artistinresidency-section1/ArtistInResidencySection1";
import ArtistInResidencySection2 from "./artistinresidency/artistinresidency-section2/ArtistInResidencySection2";
import ArtistInResidencySection3 from "./artistinresidency/artistinresidency-section3/ArtistInResidencySection3";
import ArtistInResidencySection4 from "./artistinresidency/artistinresidency-section4/ArtistInResidencySection4";

import AboutSection1 from "./about/about-section1/AboutSection1";
import AboutSection2 from "./about/about-section2/AboutSection2";
import AboutSection3 from "./about/about-section3/AboutSection3";
import AboutSection4 from "./about/about-section4/AboutSection4";
import AboutSection5 from "./about/about-section5/AboutSection5";

import Footer from "./footer/Footer";

import BookstoreSection1 from "./bookstore/bookstore-section1/BookstoreSection1";
import BookstoreSection2 from "./bookstore/bookstore-section2/BookstoreSection2";

import ArtistProfileSection1 from "./artistprofile/artistprofile-section1/ArtistProfileSection1";
import ArtistProfileSection2 from "./artistprofile/artistprofile-section2/ArtistProfileSection2";
import ArtistProfileSection3 from "./artistprofile/artistprofile-section3/ArtistProfileSection3";

import TriangleSection1 from "./triangle/triangle-section1/TriangleSection1";
import TriangleSection2 from "./triangle/triangle-section2/TriangleSection2";
import TriangleSection3 from "./triangle/triangle-section3/TriangleSection3";
import TriangleSection4 from "./triangle/triangle-section4/TriangleSection4";
import TriangleSection5 from "./triangle/triangle-section5/TriangleSection5";
import TriangleSection6 from "./triangle/triangle-section6/TriangleSection6";
import TriangleSection7 from "./triangle/triangle-section7/TriangleSection7";

import ProgramPageSection1 from "./programpage/programpage-section1/ProgramPageSection1";
import ProgramPageSection2 from "./programpage/programpage-section2/ProgramPageSection2";
import ProgramPageSection3 from "./programpage/programpage-section3/ProgramPageSection3";

import NewsletterSubscription from "./newsletter-subscription/NewsletterSubscription";
import AnnouncementBar from "./announcementbar/AnnouncementBar";
import LineSeperator from "./line-seperator/LineSeperator";

import GlobalSearchSection1 from "./search/globalsearchsection1/GlobalSearchSection1";
import GlobalSearchDisplaySection from "./search/globalsearchdisplaysection/GlobalSearchDisplaySection";

import TrianglePageSection1 from "./trianglepage/trianglepage-section1/TrianglePageSection1";
import TrianglePageSection2 from "./trianglepage/trianglepage-section2/TrianglePageSection2";
import TrianglePageSection3 from "./trianglepage/trianglepage-section3/TrianglePageSection3";

import TagsDisplay from "./tagsdisplay/TagsDisplay";
import ArticleAboutProject from "./articleaboutproject/ArticleAboutProject";
import AuthorSection from "./authorsection/AuthorSection";

import DisplayArticleContent from "./displayarticlecontent/DisplayArticleContent";

import RenderArticleContent from "./renderarticlecontent/RenderArticleContent";

import SimpleSectionOne from "./simplesectionone/SimpleSectionOne";

// exports here
export {
  MainNavBar,
  HomeSection1,
  HomeSection2,
  HomeSection3,
  HomeSection4,
  HomeSection5,
  Footer,
};

//programs page  exports
export { ProgramGrid, PreviousProgramsSearch, UpcomingProgramList };

//possibilities export
export { PossibilitiesSection1, PossibilitiesSearch, PossibilitiesMandate };

//art and public exports
export {
  ArtAndPublicSection1,
  ArtAndPublicSection2,
  ArtAndPublicSection3,
  ArtAndPublicSection4,
  ArtAndPublicSection5,
  ArtAndPublicSection6,
};

export {
  ArtistInResidencySection1,
  ArtistInResidencySection2,
  ArtistInResidencySection3,
  ArtistInResidencySection4,
};

export {
  AboutSection1,
  AboutSection2,
  AboutSection3,
  AboutSection4,
  AboutSection5,
};

export { BookstoreSection1, BookstoreSection2 };

export { ArtistProfileSection1, ArtistProfileSection2, ArtistProfileSection3 };

export { ProgramPageSection1, ProgramPageSection2, ProgramPageSection3 };

export {
  TriangleSection1,
  TriangleSection2,
  TriangleSection3,
  TriangleSection4,
  TriangleSection5,
  TriangleSection6,
  TriangleSection7,
};

export {
  TrianglePageSection1,
  TrianglePageSection2,
  TrianglePageSection3,
};

// other  components

export { NewsletterSubscription };
export { AnnouncementBar };
export { LineSeperator };

// search global sections

export { GlobalSearchSection1, GlobalSearchDisplaySection };

export {
  TagsDisplay,
  AuthorSection,
  ArticleAboutProject,
  DisplayArticleContent,
};

export { RenderArticleContent }

export { SimpleSectionOne }