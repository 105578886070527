import React from "react";
import "./styles.css";

import { Row, Col, Button } from "antd";

export default function TriangleSection7() {
  return (
    <div className="trianglesection7__container">
      <div className="trianglesection7__content">
        <div className="trianglesection7__info-text">
          <p className="trianglesection7__text-line1">OLD TREE:</p>
          <p className="trianglesection7__text-line2 text-timesnewroman">
            Tormod Haugland
          </p>
          <p className="trianglesection7__text-line2 text-timesnewroman">
            Morten Langeland
          </p>
          <p className="trianglesection7__text-line2 text-timesnewroman">
            Eira Søyseth
          </p>
          <Button className="btn-primary-hks">Litterær respons</Button>
        </div>
      </div>
    </div>
  );
}
