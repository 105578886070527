import React from "react";
import "./styles.css";

import BookstoreImage from "../../../../images/bookstore.png";
import { Row, Col } from "antd";
export default function BookstoreSection2() {
  return (
    <div className="bookstoresection2__container">
      <p className="bookstoresection2__title">Cafe</p>
      <Row gutter={33} className="bookstoresection2__content">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 15 }}
          xl={{ span: 15 }}
          xxl={{ span: 15 }}
        >
          <div className="bookstoresection2__left-image">
            <img src={BookstoreImage} />
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 9 }}
          xl={{ span: 9 }}
          xxl={{ span: 9 }}
        >
          <p className="bookstoresection2__right-description">
            Hordaland kunstsenters bokhandel har et bredt utvalg tidsskrifter og
            fagbøker knyttet til samtidskunstfeltet. Vi har blant annet en rekke
            antologier og tekstsamlinger om viktige tema for dagens
            kunstproduksjon, kunstinstitusjon og kuratorisk praksis. Vi fører
            også kataloger og bøker av kunstnere i Hordaland som kanskje ikke
            har så bred distribusjon.
          </p>

          <p className="bookstoresection2__right-link">View menu (PDF) →</p>
        </Col>
      </Row>
    </div>
  );
}
