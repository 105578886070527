import React from "react";
import "./style.css";
import { Row, Col, Button } from "antd";

import { IMAGE_BASE_URL } from "../../../../services/api";

import { formatDate } from "../../../helper/formatDate";

export default function ArtAndPublicSection6(props) {
  const { data, isLoading, error } = props.queryData;

  let gridDataList = data && data.slice(0, 2);
  return (
    <div className="artandpublicsection5__container">
      {gridDataList && (
        <Row gutter={35}>
          {gridDataList.map((item) => {
            return (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
                className="artandpublicsection5__program-info-container"
              >
                <div className="artandpublicsection5__program-info">
                  <p className="artandpublicsection5__program-info-line1">
                    {item.title}
                  </p>
                  <p className="text-timesnewroman artandpublicsection5__program-info-line1">
                    {item.artist.name}
                  </p>
                  <p className="artandpublicsection5__program-info-line3">
                    {formatDate(item.dateStart)} - {formatDate(item.dateEnd)}
                  </p>
                </div>
                <div className="artandpublicsection5__program-image">
                  <div className="artandpublicsection5__image-container">
                    {item.mainImg && (
                      <img src={`${IMAGE_BASE_URL}${item.mainImg.url}`} />
                    )}
                  </div>
                  <div className="artandpublicsection5__image-button">
                    <Button className="btn-primary-hks">Display</Button>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
}
