import React from "react";
import "./styles.css";
import ProjectImage from "../../../../images/artandpublic.png";

import { Row, Col } from "antd";
export default function ArtAndPublicSection4() {
  return (
    <div className="artandpublicsection4__container">
      <p className="artandpublicsection4__title">Kunstkonsulent</p>

      <Row gutter={48} className="artandpublicsection4__content">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}
          xxl={{ span: 10 }}
        >
          <div>
            <p className="artandpublicsection4__content-description ">
              Hordaland Kunstsenter has been working with art in public space
              since its foundation, and has for many years been the regional
              contact institution for KORO, the Norwegian government’s
              professional body for art in public spaces.
            </p>
            <p className="artandpublicsection4__content-description ">
              Hordaland Kunstsenter provides practical advice and information to
              both the public and the private sector, and aims to contribute
              with artistic professionalism in the management of public space
              and urban development.
            </p>
            <p className="artandpublicsection4__content-description ">
              Our work with art in public space is an important part of our core
              activities, and we offer a unique competence within the field. We
              will work towards securing artistic quality in public art projects
              within our region, and towards an active and critically
              reflecting, public conversation about art and our shared public
              spaces.
            </p>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 2 }}
          xl={{ span: 2 }}
          xxl={{ span: 2 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <div className="artandpublicsection4__right-text">
            <p className="artandpublicsection4__right-title">Active projects</p>
            <p className="artandpublicsection4__right-title2">
              Translating togetherness
            </p>
          </div>
          <div className="artandpublicsection4__img">
            <img src={ProjectImage} />
            <p className="artandpublicsection4__img-caption">
              Dette er en bildetekst. FOTO: Arbeidsgiver.{" "}
            </p>
          </div>
          <div className="artandpublicsection4__img">
            <img src={ProjectImage} />
            <p className="artandpublicsection4__img-caption">
              Dette er en bildetekst. FOTO: Arbeidsgiver.{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
