import React from "react";
import "./style.css";
import { Row, Col, Button, Form, Input } from "antd";

export default function AboutSection3() {
  return (
    <div className="aboutsection3__container">
      <Row gutter={12}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="aboutsection3__notice-container">
            <p className="aboutsection3__notice-title">
              Sign up for the weekly newsletter!
            </p>
            <p className="text-timesnewroman aboutsection3__notice-desc">
              Hordaland Art Center has worked with art in public spaces since
              the institution was established, and has for many years functioned
              as a regional contact institution for Art in Public Spaces (KORO),
              the state's professional body for art in public spaces.
            </p>
            <p className="text-timesnewroman aboutsection3__notice-desc">
              Hordaland Art Center assists public and private actors with advice
              and information, and wants to contribute to artistic and cultural
              awareness in the work with public spaces, urban and local
              development.
            </p>
          </div>
        </Col>
        <Col
          sxs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
          xxl={{ span: 16 }}
        >
          <div className="aboutsection3__form-container">
            <AboutSection3Form />
          </div>
        </Col>
      </Row>
    </div>
  );
}

function AboutSection3Form() {
  return (
    <div className="aboutsection3__form">
      <Form layout="horizontal">
        <Form.Item label="First Name">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name">
          <Input />
        </Form.Item>

        <Form.Item label="Email">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button>SEND</Button>
        </Form.Item>
      </Form>
    </div>
  );
}
