import React from "react";

import "./styles.css";

import { Link } from "react-router-dom";
import { Button } from "antd";

export default function Error404() {
  return (
    <div className="error404__container">
      <div className="error404__content">
        <h1>Error !</h1>
        <h1>Page Not Found.</h1>
        <Link to="/">
          <Button type="primary" className="btn-primary-hks">
            Go to Home
          </Button>
        </Link>
      </div>
    </div>
  );
}
