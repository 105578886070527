import React from "react";
import "./styles.css";
import ProjectImage from "../../../../images/artandpublic.png";

import { Row, Col } from "antd";
export default function ArtAndPublicSection3(props) {
  return (
    <div className="artandpublicsection3__container">
      <p className="artandpublicsection3__title">Mediation</p>

      <Row gutter={48} className="artandpublicsection3__content">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}
          xxl={{ span: 10 }}
        >
          <div>
            <p className="artandpublicsection3__content-description ">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. (Read more)
            </p>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 2 }}
          xl={{ span: 2 }}
          xxl={{ span: 2 }}
        ></Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <div className="artandpublicsection3__right-text">
            <p className="artandpublicsection3__right-title">Active projects</p>
            <p className="artandpublicsection3__right-title2">
              Translating togetherness
            </p>
          </div>
          <div className="artandpublicsection3__img">
            <img src={ProjectImage} />
            <p className="artandpublicsection3__img-caption">
              Dette er en bildetekst. FOTO: Arbeidsgiver.{" "}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}
