import React, { useRef, useState, useEffect } from "react";
import "./styles.css";

import { IMAGE_BASE_URL } from "../../../services/api";

import { Row, Col, Carousel, Button } from "antd";

import ReactHtmlParser from "react-html-parser";

export default function DisplayArticleContent(props) {
  const slider = useRef();
  const { articleData } = props;

  let [currentSlide, setCurrentSlide] = useState(1);
  let [totalSlide, setTotalSlide] = useState(0);
  let [captionList, setCaptionList] = useState(null);
  let [midImageCaptionList, setMidImageCaptionList] = useState(null);

  let carouselSettings = {
    dotPosition: "bottom",
    autoPlay: true,
    dots: false,
  };

  useEffect(() => {
    if (articleData.section4.images) {
      setTotalSlide(articleData.section4.images.length);
    }
  }, [articleData]);

  useEffect(() => {
    if (articleData.section4.captions) {
      setCaptionList(articleData.section4.captions.split("#"));
    }
    if (articleData.section2.captions) {
      setMidImageCaptionList(articleData.section2.captions.split("#"));
    }
  }, [articleData]);

  // console.log(articleData);

  const nextSlide = () => {
    slider.current.next();
    if (currentSlide === totalSlide) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const prevSlide = () => {
    slider.current.prev();
    if (currentSlide <= 1) {
      setCurrentSlide(totalSlide);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const handleBeforeCarouselChange = (before, after) => {
    console.log("before:", before + 1, " after: ", after + 1);
    setCurrentSlide(after + 1);
  };
  return (
    <div className="displayarticlecontent__container">
      {/* section1 */}
      <div className="content-container-wrapper">
        <div className="content-container-content">
          <div className="displayarticlecontent__section1 lh-1-4em">
            <div className="ck-content">
              {ReactHtmlParser(articleData.section1)}
            </div>
          </div>
        </div>
      </div>
      {/* section 2  */}
      {/* two images */}
      <div className="content-container-wrapper">
        <div className="content-container-content">
          <div className="displayarticlecontent__section2">
            <Row align="middle" gutter={[20, 20]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 11 }}
                xl={{ span: 11 }}
                xxl={{ span: 11 }}
              >
                {articleData.section2.image1 && (
                  <div className="displayarticlecontent__section2__image">
                    <img
                      src={`${IMAGE_BASE_URL}${articleData.section2.image1.url}`}
                      alt=""
                    />
                    <p>
                      {" "}
                      {midImageCaptionList &&
                        midImageCaptionList.length > 0 &&
                        midImageCaptionList[0]}
                    </p>
                  </div>
                )}
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 2 }}
                xl={{ span: 2 }}
              ></Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 11 }}
                xl={{ span: 11 }}
                xxl={{ span: 11 }}
              >
                {articleData.section2.image2 && (
                  <div className="displayarticlecontent__section2__image">
                    <img
                      src={`${IMAGE_BASE_URL}${articleData.section2.image2.url}`}
                      alt=""
                    />
                    <p>
                      {" "}
                      {midImageCaptionList &&
                        midImageCaptionList.length > 0 &&
                        midImageCaptionList[1]}
                    </p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {/* section3 */}
      <div className="content-container-wrapper">
        <div className="content-container-content">
          <div className="displayarticlecontent__section3">
            <div className="ck-content">
              {ReactHtmlParser(articleData.section3)}
            </div>
          </div>
        </div>
      </div>

      {/* section4 */}
      {articleData.section4.images && (
        <div>
          <div className="displayarticlecontent__section4__carousel">
            <Carousel
              {...carouselSettings}
              ref={(ref) => {
                slider.current = ref;
              }}
              beforeChange={(b, a) => {
                handleBeforeCarouselChange(b, a);
              }}
            >
              {articleData.section4.images.map((imageItem) => {
                return (
                  <div
                    className="displayarticlecontent__section4__image"
                    key={imageItem.url}
                  >
                    <img src={`${IMAGE_BASE_URL}${imageItem.url}`} alt=""
                    />
                  </div>
                );
              })}
            </Carousel>

            <div className="content-container-wrapper">
              <div className="content-container-content">
                <div className="displayarticlecontent__section4__caption">
                  <Row>
                    <Col span={16}>
                      <p className="displayarticlecontent__section4__slider-caption">
                        {captionList &&
                          captionList.length > 0 &&
                          captionList[currentSlide - 1]}
                      </p>
                    </Col>
                    <Col span={8}>
                      <div className="displayarticlecontent__section4__slider-control fs-1-2em">
                        <span onClick={prevSlide}>{`<`}</span>
                        <span>
                          {currentSlide}/{totalSlide}
                        </span>
                        <span onClick={nextSlide}>{`>`}</span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* section5 */}
      <div className="content-container-wrapper">
        <div className="content-container-content">
          <div className="displayarticlecontent__section5">
            <div className="ck-content">
              {ReactHtmlParser(articleData.section5)}
            </div>
          </div>
        </div>
      </div>

      {/* section6 */}
      <div className="content-container-wrapper">
        <div className="content-container-content">
          <div className="displayarticlecontent__section6">
            <div className="displayarticlecontent__section6__quote">
              <div className="ck-content">
                {ReactHtmlParser(articleData.section6)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* section7 */}
      <div className="content-container-wrapper">
        <div className="content-container-content">
          <div className="displayarticlecontent__section7">
            <div className="ck-content">
              {ReactHtmlParser(articleData.section7)}
            </div>
          </div>
        </div>
      </div>

      {/*  */}
    </div>
  );
}
