import React from "react";

import "./styles.css";
import Section1Banner from "../../../../images/section1_banner.png";

import { Row, Col, Button } from "antd";

export default function TriangleSection6() {
  return (
    <div className="trianglesection6__container">
      <Row gutter={12}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="trianglesection6__right-container">
            <img src={Section1Banner} />
            <div className="trianglesection6__info-button">
              <Button className="btn-primary-hks">Display</Button>
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 16 }}
          xl={{ span: 16 }}
          xxl={{ span: 16 }}
        >
          <div className="homesection1__left-container">
            <div className="trianglesection6__content-archive">
              <p className="trianglesection6__content-archive-title">
                Arkivhistorier 1:
              </p>
              <p className="homesection1__content-archive-line1 text-timesnewroman">
                Intervju med Svein Rønning
              </p>
              <p className="homesection1__content-archive-line2">09.01.21</p>
            </div>

            <div className="trianglesection6__content-text">
              <p className="trianglesection6__content-text-line1 text-timesnewroman">
                «En del av kunstnerens profesjon er å kommunisere»
              </p>
              <p className="trianglesection6__content-text-line2">
                Svein Rønning
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
