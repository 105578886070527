import React from "react";

import "./styles.css";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { Row, Col, Button } from "antd";

import { formatDate } from "../../../helper/formatDate";

export default function HomeSection5(props) {
  const { data, isLoading, error } = props.queryData;

  return (
    <div className="homesection5__container">
      {data && (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
          >
            <div className="homesection1__left-container">
              <div className="homesection5__content-archive">
                <p className="homesection5__content-archive-title">
                  {data[0].title}
                </p>
                <p className="homesection1__content-archive-line1 text-timesnewroman">
                  {data[0].subtitle}
                </p>
                <p className="homesection1__content-archive-line2">
                  {formatDate(data[0].date)}
                </p>
              </div>

              <div className="homesection5__content-text">
                <p className="homesection5__content-text-line1 text-timesnewroman">
                  {data[0].shortDescription}
                </p>
                <p className="homesection5__content-text-line2">
                  {data[0].shortDescriptionCaption}
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <div className="homesection5__right-container">
              {data[0].mainImage && (
                <img src={`${IMAGE_BASE_URL}${data[0].mainImage.url}`} />
              )}
              <div className="homesection5__info-button">
                <Button className="btn-primary-hks">Display</Button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
