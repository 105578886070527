import React from "react";

import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { formatDate } from "../../../helper/formatDate";

import LocalizationContext from "../../../../localization/LocalizationContext";

export default function ArtAndPublicSection5(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { isLoading, data, error } = props.queryData;
  // console.log(data);
  const opportunityList = data && data.slice(0, 2);

  return (
    <div className="artandpublicsection5__container">
      {data && (
        <Row gutter={16}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
            className="artandpublicsection5__content-container"
          >
            <Row gutter={16}>
              {data.map((item) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                  xxl={{ span: 12 }}
                >
                  <Link to={`/possibilities/${item.id}`}>
                    <div className="artandpublicsection5__content-inner-container">
                      <p className="artandpublicsection5__content-line1 text-arial">
                        {item.title}
                      </p>
                      <p className="artandpublicsection5__content-line2 text-timesnewroman">
                        {item.description.slice(0, 120)}
                      </p>
                      <p className="artandpublicsection5__content-line3 text-arial">
                        {t("Application Deadline")}: {formatDate(item.applicationDeadline)}
                      </p>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <div className="artandpublicsection5__content-more">
              <Link to="/possibilities">
                <p>SE FLERE MULIGHETER</p>
              </Link>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
