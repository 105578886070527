import React from "react";

import {
  Home,
  Program,
  Possibilities,
  Triangle,
  ArtAndPublic,
  About,
  ArtistInResidency,
  Bookstore,
  ArtistProfile,
  AboutHKS,
  ProgramPage,
  PossibilitiesPage,
  TrianglePage,
  EmployeeProfile,
  Search,
} from "./components/pages/Pages";

import Error404 from "./components/pages/errorpage/404/404";

import { Switch, Route } from "react-router-dom";

//localization context
// Great work Stack-24
import LocalizationProvider from "./localization/LocalizationProvider";
import LocalizationContext from "./localization/LocalizationContext";


//react query setup
import { QueryClient, QueryClientProvider } from "react-query";
import ProjectPage from "./components/pages/projectpage/ProjectPage";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    },
  },
});

function App() {
  return (
    <>
      <LocalizationProvider>
        <QueryClientProvider client={queryClient}>
          <div className="app__wrapper">
            <Switch>
              {/* routes that get dynamic content based on url */}
              <Route path="/artistprofile/:profileID">
                <ArtistProfile />
              </Route>

              <Route path="/empProfile/:profileID">
                <EmployeeProfile />
              </Route>

              <Route path="/triangle/:triangleID">
                <TrianglePage />
              </Route>

              <Route path="/program/:programID">
                <ProgramPage />
              </Route>

              <Route path="/possibilities/:possibilityID">
                <PossibilitiesPage />
              </Route>

              <Route path="/projects/:projectID">
                <ProjectPage />
              </Route>

              {/* <Route path="/employee/:employeeID">
                <EmployeePage />
              </Route> */}

              <Route path="/abouthks">
                <AboutHKS />
              </Route>

              <Route path="/search/:param/:name">
                <Search />
              </Route>

              <Route path="/search">
                <Search />
              </Route>

              {/* default routes */}
              <Route exact path="/program">
                <Program />
              </Route>
              <Route path="/possibilities">
                <Possibilities />
              </Route>
              <Route path="/triangle">
                <Triangle />
              </Route>
              <Route path="/artandpublic">
                <ArtAndPublic />
              </Route>
              <Route path="/artistinresidency">
                <ArtistInResidency />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/bookstore">
                <Bookstore />
              </Route>

              {/* default route if nothing matches shows home page */}
              <Route path="/">
                <Home />
              </Route>

              {/* 404 */}
              {/* <Route exact path="/404">
                <Error404 />
              </Route> */}
              {/* 
              <Route path="*">
                <Error404 />
              </Route> */}
            </Switch>
          </div>
        </QueryClientProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
