import React from "react";

import "./styles.css";
import Section1Banner from "../../../../images/section1_banner.png";

import { Row, Col, Button } from "antd";

export default function TriangleSection5() {
  return (
    <div className="trianglesection5__container">
      <Row gutter={12} justify="end">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="homesection1__left-container">
            <div className="trianglesection5__content-archive">
              <p className="trianglesection5__content-archive-title">
                No man is an Island
              </p>
              <p className="homesection1__content-archive-line1 text-timesnewroman">
                Brev fra Svein Rønning
              </p>
              <p className="homesection1__content-archive-line2">
                09.01.21–31.01.21
              </p>
            </div>

            <div className="trianglesection5__content-text">
              <p className="trianglesection5__content-text-line1">
                As the first contributor, we have invited Svein Rønning -
                artist, neighbor and former chairman of the board of Hordaland
                Art Center - to write a letter addressed to the art center.
              </p>
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 8 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="trianglesection5__right-container">
            <img src={Section1Banner} />
            <div className="trianglesection5__info-button">
              <Button className="btn-primary-hks">Display</Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
