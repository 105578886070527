import React from "react";
import "./styles.css";
import Section1Banner from "../../../../images/section1_banner.png";

import { Row, Col } from "antd";
export default function PossibilitiesMandate() {
  return (
    <div className="possibilitiesmandate__container">
      <Row gutter={12}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 14 }}
          xl={{ span: 14 }}
          xxl={{ span: 14 }}
        >
          <div className="possibilitiesmandate__content">
            <p className="possibilitiesmandate__content-title">Our Mandate:</p>
            <p className="possibilitiesmandate__content-description ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
              tincidunt et ex sed tempor. Nunc ac venenatis lorem. Fusce laoreet
              arcu in erat luctus iaculis. Quisque id enim convallis, dignissim
              velit in, fermentum justo. In quam turpis, sollicitudin vel
              finibus et, iaculis a orci. Sed ante turpis, euismod vulputate
              nisi vitae, molestie sollicitudin augue. Suspendisse sit amet
              lobortis turpis. Mauris quis elit tellus. Quisque nec gravida
              orci, eu elementum metus. Quisque et placerat leo, tempus
              ultricies nulla. Etiam at mattis mi, ac varius mauris. Aenean in
              velit nec diam luctus finibus. (les mer)
            </p>
            <p className="possibilitiesmandate__content-date">
              Linkes til KUNST OG OFFENTLIGHETEN
            </p>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 10 }}
          xl={{ span: 10 }}
          xxl={{ span: 10 }}
        >
          <div className="possibilitiesmandate__img">
            <img src={Section1Banner} />
          </div>
        </Col>
      </Row>
    </div>
  );
}
