import React from "react";
import "./styles.css";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { Row, Col } from "antd";
export default function TrianglePageSection2(props) {
  const { isLoading, error, data } = props.queryData;
  return (
    <div className="trianglepagesection2__container">
      {data && (
        <div className="trianglepagesection2__contentSection">
          <div className="ck-content">{ReactHtmlParser(data.description)}</div>
        </div>
      )}
    </div>
  );
}
